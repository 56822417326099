<template>
    <div class="auth-bg bg-cover">
        <div class="nav-container bg-transparent pt-3">
            <lego-nav-bar drop-down-animation="fade" drop-down-animation-mobile="fade" :colored="true" :items="menu"
                          shadow="0" class="container" @link-event="linkEvent">
                <template #logo>
                    <router-link to="/">
                        <img src="../assets/mobile/home/logo/Bank-e-Auctions.png" alt="">
                    </router-link>
                </template>
            </lego-nav-bar>
        </div>
        <div class="page-content">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>
    </div>
</template>

<script>
import getMenu from '../data/authMenu';
import LegoNavBar from 'lego-framework/src/components/NavBar';
import axios from 'secure-axios';
import urls from '../data/urls';

export default {
    components: {
        LegoNavBar
    },
    data () {
        return {
            // menu: getMenu(),
            tutorial_menu: {},
            auctionLink: '#',
            value: '',
            menu: []
        };
    },
    metaInfo: {
        titleTemplate: '%s - Medico Pharm',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout',
    beforeMount () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.socialMedia);
            const json = response.data;
            if (json.error === false) {
                this.details = { ...json.data };
                this.tutorial_menu = json.data;
                this.setOptions(json.links.data);
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        },

        linkEvent (name) {
            if (name === 'goto-register') {
                this.$router.push('/register/');
            }

            if (name === 'user-manuel') {
                this.getUserManual();
            }

            if (name.split(' ')[0] === 'tutorial') {
                this.showTutorial(name.split(' ')[1]);
            }

            if (name.split(' ')[0] === 'user-manual') {
                this.showUserManual(name.split(' ')[1]);
            }
        },

        showTutorial (index) {
            window.open(this.tutorial_menu.tutorial[index].tutorial, '_blank');
        },

        showUserManual (index) {
            window.open(this.tutorial_menu.user_manual[index].user_manual, '_blank');
        },
        setOptions (links) {
            const propertyMenu = this.getPropertyMenu(links);
            this.auctionLink = propertyMenu.length > 0 ? propertyMenu[0].link : null;
            const tutorialMenu = this.getTutorialMenu();
            this.menu = getMenu(propertyMenu, tutorialMenu);
        },
        getPropertyMenu (links) {
            const propertyMenu = [];
            for (let i = 0; i < links.length; i++) {
                propertyMenu.push({ text: links[i].label, link: '/auctions/' + links[i].label + '/' });
            }
            return propertyMenu;
        },
        getTutorialMenu () {
            const tutorial = [];
            const userManual = [];
            if (this.tutorial_menu.tutorial.length !== 0) {
                for (let i = 0; i < this.tutorial_menu.tutorial.length; i++) {
                    tutorial.push({ text: this.tutorial_menu.tutorial[i].title, event: 'tutorial ' + i });
                }
            }
            if (this.tutorial_menu.user_manual.length !== 0) {
                for (let i = 0; i < this.tutorial_menu.user_manual.length; i++) {
                    userManual.push({ text: this.tutorial_menu.user_manual[i].title, event: 'user-manual ' + i });
                }
            }
            return {
                userManual: userManual,
                tutorials: tutorial
            };
        }
    }
};

</script>
<style lang="scss">
.auth-bg {
    background: url("../assets/mobile/log in/bgi.png");
    background-size: cover;
    @media (min-width: 992px) {
        background: url("../assets/web/log in/auth-bg.png");
        background-size: contain;
    }
    background-repeat: no-repeat;
    background-position: center;
}
</style>
