import store from '../store';

const getMenu = function (auction, tutorialMenuItems) {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon: '',
            text: 'Home',
            link: '/'
        },
        {
            text: 'About Us',
            link: '/about/'
        },
        {
            icon: '',
            text: 'Auction',
            link: '/auctions/upcoming/'
        }
    ];
    const tutorialItemsMenu = [];

    if (tutorialMenuItems.userManual.length !== 0) {
        tutorialItemsMenu.push({
            text: 'User Manual',
            items: tutorialMenuItems.userManual,
            class: 'menu-level-2'
        });
    }

    if (tutorialMenuItems.tutorials.length !== 0) {
        tutorialItemsMenu.push({
            text: 'Video Tutorials',
            items: tutorialMenuItems.tutorials,
            class: 'menu-level-2'
        });
    }

    if (tutorialItemsMenu.length !== 0) {
        menu.push({
            text: 'Tutorial',
            link: '#',
            items: tutorialItemsMenu
        });
    }

    menu.push(
        {
            icon: '',
            text: 'FAQ',
            link: '/faq/'
        },
        {
            text: 'Media',
            link: '#',
            items: [
                {
                    text: 'News',
                    link: '/media/news/'
                },
                {
                    text: 'Blogs',
                    link: '/media/blog/'
                }
            ]
        },
        {
            icon: '',
            text: 'Contact Us',
            link: '/contact/'
        },
        {
            icon: '',
            text: 'Login',
            link: '/login/'
        },
        {
            text: 'Register',
            link: '/register/',
            class: 'nav-btn-cont',
            btnAttrs: {
                design: 'basic-b',
                color: 'white',
                class: 'border-white px-4',
                borderRadius: '0'
            }
        })
    ;

    const adminMenu = [
        {
            text: 'Admin Master Data',
            link: '#',
            items: [
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                },
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                }
            ]
        }
    ];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...menu,
            ...adminMenu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;
